










































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class MunicipioInforma extends Vue {
  private win = window;
  private avisos: any = [];
     private nombreWeb: any = "";

    private currentPage = 1;
    private total = 0;
    private mostrar = false;
  
    @Watch("currentPage")
    private currentPageWatch(newValue: any) {
      if (newValue >= 0 && newValue <= Math.ceil(this.total / 12)) {
        this.getInfoAvisos();
      }
    }
  
    private mounted() {
      this.getInfoAvisos();
    }
  
    private getInfoAvisos() {
      this.$q.loading.show({
        delay: 100, // ms
      });
      this.$axios
        .get("avisos?pagina=" + this.currentPage)
        .then((res: any) => {
            // console.log(res)
          if(res.data.avisos.length == 0){
            this.mostrar = true;
          }
          // console.log("docu", res.data);
          this.avisos = res.data.avisos;
          
          this.total = res.data.total;
          this.$q.loading.hide();
        })
        .catch((err: any) => {
          this.$q.loading.hide();
          console.log(err);
        });
    }
  
   


  
}
