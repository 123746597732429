














































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import VueRecaptcha from "vue-recaptcha";

interface dataCorreo {
  rut: string;
  nombre: string;
  email: string;
  direccion_personal: string;
  direccion_acontecimiento: string;
  telefono_fijo: string;
  telefono_celular: string;
  boletin: boolean;
  mensaje: string;
}

@Component({
  components: { VueRecaptcha },
})
export default class FormularioGeneral extends Mixins(FormValidator) {
  private rutContacto = "";
  private dataFormulario: any = {
    id: "",
    id_unidad: "",
    nombre: "",
    nombre_web: "",
    titulo: "",
    email: "",
  };
  private nombreWeb = "";
  private idContacto = "";
  private correoData: dataCorreo = {
    rut: "",
    nombre: "",
    email: "",
    direccion_personal: "",
    direccion_acontecimiento: "",
    telefono_fijo: "",
    telefono_celular: "",
    boletin: false,
    mensaje: "",
  };


  @Watch("rutContacto")
  private formatRutVecino(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.correoData.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.rutContacto = `${rut}-${dv}`;
    } else {
      this.rutContacto = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  private beforeMount() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      if (
        this.$router.currentRoute.params.id &&
        this.$router.currentRoute.params.id != ""
      ) {
        this.idContacto = this.$router.currentRoute.params.id;
      }
      this.getDataForm();
    } else {
      this.routerGo("Home");
    }
  }
  private mounted() {}
  private getDataForm() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    if (this.idContacto == "") {
      this.idContacto = "-1";
    }
    this.$axios
      .get(
        "formulario?nombre_web=" +
          this.nombreWeb +
          "&id_contacto=" +
          this.idContacto
      )
      .then((res: any) => {
        this.dataFormulario = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema, intentelo más tarde.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        this.routerGo("Home");
      });
  }

  private enviarCorreo() {
    // console.log(this.correoData);
    if (
      this.correoData.rut == "" ||
      this.validateRut(this.correoData.rut) == false
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.correoData.nombre == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su nombre y apellidos.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.correoData.email == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Correo electrónico.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (!this.validEmail(this.correoData.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo electrónico válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
 
    if (this.correoData.direccion_personal == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Dirección personal.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.correoData.direccion_acontecimiento == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese Ubicación del problema.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.correoData.mensaje == "") {
      Notify.create({
        color: "negative",
        message:
          "Por favor, ingrese un mensaje para conocer más de su contacto.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    this.$q.loading.show({
      delay: 200, // ms
    });
    this.$axios
      .post("formulario", {
        nombre: this.correoData.nombre,
        email: this.correoData.email,
        rut: this.correoData.rut,
        direccion_personal: this.correoData.direccion_personal,
        direccion_acontecimiento: this.correoData.direccion_acontecimiento,
        telefono_fijo: this.correoData.telefono_fijo,
        telefono_celular: this.correoData.telefono_celular,
        mensaje: this.correoData.mensaje,
        email_contacto: this.dataFormulario.email,
        id_contacto: this.dataFormulario.id,
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente su mensaje.",
          icon: "report_problem",
          position: "top",
          timeout: 2500,
        });
        this.$router.push({ name: "Home" }).catch((err) => {
          console.log(err);
        });
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        const mensaje = err.response.data.message;
        if (mensaje == "NOT_ALLOW") {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un problema, intentelo más tarde.",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        } else if (mensaje == "INVALID_PARAM_RUT") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un rut válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        } else if (mensaje == "INVALID_RUT") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un rut válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        } else if (mensaje == "INVALID_PARAM_NOMBRE") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un nombre válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        } else if (mensaje == "INVALID_PARAM_EMAIL") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un correo válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        } else if (mensaje == "INVALID_PARAM_DIRECCION") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar una dirección válida.",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        } else if (mensaje == "INVALID_PARAM_MENSAJE") {
          Notify.create({
            color: "negative",
            message: "Debe ingresar un mensaje válido.",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        } else {
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un problema, intentelo más tarde.",
            icon: "report_problem",
            position: "top",
            timeout: 2500,
          });
          return;
        }
      });
 
    }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
